/* eslint-disable no-redeclare */
import { CardTypes, CardTypesValues } from './general';

type CardAttributes<Type extends CardTypesValues> = {
  id: Type;
  tip: string;
  errors: Record<string, string | boolean>;
};

const buildCardAttribute = <Type extends CardTypesValues>(
  data: CardAttributes<Type>
) => ({ [data.id]: data }) as { [key in Type]: CardAttributes<Type> };

export const cardAttributes = {
  ...buildCardAttribute({
    id: CardTypes.TEXT,
    tip: 'Basic messages sent to users',
    errors: {
      blankText: 'Card text cannot be blank',
      toomuchtext: 'Testing validation',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.DELAY,
    tip: ' If you want to add a delay between cards to give users more time to read.',
    errors: {
      maxValue: 'Delay value cannot exceed 10000',
      status: false,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.IMAGE,
    tip: 'Use rich media to deliver a better experience.',
    errors: {
      noUrl: 'Please upload an image',
      invalid: 'Image you have uploaded is invalid',
      size: 'Please upload an image upto 2 mb in size',
      status: false,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.QUICK_REPLY,
    tip: 'Predefined responses users can click on.',
    errors: {
      blankText: 'Text cannot be blank',
      status: true,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.VIDEO,
    tip: 'Use rich media to deliver a better experience.',
    errors: {
      noUrl: 'Please upload a video',
      status: false,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.HANDOVER,
    tip: 'Hand over the conversation to a live agent.',
    errors: {
      invalidAppId: 'The app id you provided does not exist',
      status: false,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.CAROUSEL,
    tip: 'Carousel to visually engage users with a richer experience.',
    errors: {
      general: 'URL, image and title are mandatory in carousel cards!',
      noHeading: 'Please add a title to carousel slide',
      noImageUrl: 'Please select an image for carousel slide',
      noClickUrl: 'Providing a URL is mandatory for carousel slide',
      status: true,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.API,
    tip: 'For advanced functionalities, you can make API calls to external services.',
    errors: {
      invalidJSON: 'The JSON profided is not valid',
      noURL: 'API URL cannot be blank',
      status: true,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.ATTRIBUTE,
    tip: 'Collect user information or preferences throughout the conversation.',
    errors: {
      noValue: 'Please create a set attribute and enter set attribute value',
      noAttribute: 'Please select an attribute',
      status: true,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.GOTO,
    tip: 'Redirect the user to a different block based on the value assigned to attributes.',
    errors: {
      noIf: 'An if attribute is mandatory',
      noElse: 'An else attribute is mandatory',
      noNull: 'A null attribute is mandatory',
      status: true,
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.DATA_SUBMIT,
    tip: 'Send collected data at a specific point in the conversation.',
    errors: {
      blankText: 'Name Lead field cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.SMS,
    tip: 'If you need to follow up the conversation via SMS, this card facilitates that.',
    errors: {
      blankText: 'SMS Text cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.GOAL,
    tip: 'Set financial or numerical objectives in your chatbot flows.',
    errors: {
      blankText: 'GOAL Name cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.FALLBACK,
    tip: 'Fallback Card',
    errors: {
      blankText: 'Fallback Name cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.INSTA_STACK_API,
    tip: 'Answer users questions using Generative AI applied on your data.',
    errors: {},
  }),
  ...buildCardAttribute({
    id: CardTypes.SMS_VALIDATION,
    tip: 'Verify phone number collected prior to sending an SMS.',
    errors: {},
  }),
  ...buildCardAttribute({
    id: CardTypes.SET_AI,
    tip: 'Lets you use AI to manipulate attribute data to your liking or extract an attribute from a longer piece of text.',
    errors: {
      blankText: 'Prompt cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.INTENT_AI,
    tip: 'Lets you use AI to classify intents.',
    errors: {
      blankText: 'Prompt cannot be blank',
    },
  }),
  ...buildCardAttribute({
    id: CardTypes.FORM_AI,
    tip: 'Lets you use AI to create forms.',
    errors: {
      blankText: 'Prompt cannot be blank',
    },
  }),
} as const;

export const generalSaveError = 'Please complete all fields before saving';

export const LinkTypes = {
  URL: 1,
  EMAIL: 2,
  PHONE: 5,
  FORM: 10,
  BLOCK: 9,
  QR_URL: 101,
  QR_PHONE: 102,
  QR_EMAIL: 103,
  QR_INTENT: 104,
  QR_FORM: 105,
} as const;

export type LinkTypes = EnumValueType<typeof LinkTypes>;

export const ChoiceTypes = {
  IF: 3,
  NULL: 5,
  ELSE: 4,
  OFFLINE: 7,
  RETURN_ONLINE: 8,
  VALID_SMS: 9,
  INVALID_SMS: 10,
  ERROR_SMS: 11,
  IN_USE_SMS: 12,
};

export type ChoiceTypes = EnumValueType<typeof ChoiceTypes>;

export const drawerWidth = 60;
export const headerHeight = 60;
export const LOG_ROCKET_KEY = 'uyk4tt/authoring-tool';
