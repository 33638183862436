import { Box } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BlockCard, Button, theme, Typography } from '@botco/library';
import APIIcon from '~/assets/icons/card/api.svg?react';
import { cardAttributes } from '~/constants';
import { CardTypes } from '~/constants/general';
import { useAttributeContext } from '~/hooks/useAttributeContext';
import {
  addApiCardAttribute,
  addApiCardParam,
  removeApiCardParam,
} from '~/redux/actions/conversationDataR';
import { isWhiteSpaceString, showToast } from '~/utils';
import { Attribute } from '~/utils/http/agent/types';

import { ApiUsageTypeEnum, IntentApiParam } from './APICard.types';
import { CardPropsType } from '../types';
import { APIActionForm } from './components/APIActionForm';
import { APICardButton } from './components/APICardButton';
import { APICardTabs } from './components/ApiCardTabs';
import { APIParameterModal } from './components/APIParameterModal/APIParameterModal';

type PropsType = CardPropsType;

export const PAYLOAD_ATTRIBUTE = '_payload' as const;
export const STATUS_CODE_ATTRIBUTE = '_status_code' as const;

export const APICard = (props: PropsType) => {
  const dispatch = useDispatch();
  const [openParamModal, setOpenParamModal] = React.useState(false);
  const [selectedParamIndex, setSelectedParamIndex] = React.useState(-1);

  const handleDeleteCard = () => {
    props.openConfirmDialog(props.orderIndex);
  };

  const { createAttribute, attributes } = useAttributeContext();

  const { data } = useSelector(({ conversationsDataR }) => ({
    data: conversationsDataR.intentCardsData[props.orderIndex],
  }));

  const { api_action, api_params = [] } = data ?? {};

  const payloadAttributeExist = attributes.find(
    ({ cust_attr_name }) => cust_attr_name === PAYLOAD_ATTRIBUTE
  );

  const statusCodeAttributeExist = attributes.some(
    ({ cust_attr_name }) => cust_attr_name === STATUS_CODE_ATTRIBUTE
  );

  React.useEffect(() => {
    if (statusCodeAttributeExist) return;

    const timeout = window.setTimeout(() => {
      createAttribute(STATUS_CODE_ATTRIBUTE);
    }, 200);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [statusCodeAttributeExist, createAttribute]);

  const addDefaultAttribute = React.useCallback(
    (attribute: Attribute) => {
      const alreadyExist = data.api_attributes?.some(
        (a) => a.cust_attr_name === attribute.cust_attr_name
      );

      if (alreadyExist) return;

      dispatch(
        addApiCardAttribute(props.orderIndex, -1, {
          cust_attr_id: attribute.cust_attr_id,
          cust_attr_name: attribute.cust_attr_name,
          cust_attr_type: attribute.cust_attr_type,
          attr_value_jsonpath: '$',
          attr_usage_type: ApiUsageTypeEnum.SUCCESS,
        })
      );
    },
    [data.api_attributes, dispatch, props.orderIndex]
  );

  React.useEffect(() => {
    if (payloadAttributeExist) {
      addDefaultAttribute(payloadAttributeExist);
      return;
    }

    const timeout = window.setTimeout(async () => {
      const payloadAttribute = await createAttribute(PAYLOAD_ATTRIBUTE);
      addDefaultAttribute(payloadAttribute);
    }, 200);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [payloadAttributeExist, createAttribute, addDefaultAttribute]);

  const handleRemoveParam = (paramIndex: number) => {
    dispatch(removeApiCardParam(props.orderIndex, paramIndex));
  };

  const handleOpenParamModal = (index = -1) => {
    setOpenParamModal(true);
    setSelectedParamIndex(index);
  };

  const handleAddParam = (param: IntentApiParam) => {
    const { api_param_name: name, api_param_value: value } = param;

    if (isWhiteSpaceString(name) || isWhiteSpaceString(value)) {
      showToast('error', 'Please enter both required fields');
      return;
    }

    const existingKeys =
      props.orderIndex === -1
        ? data?.api_params?.map((p) => p.api_param_name)
        : data?.api_params
            ?.filter((_, i) => i !== selectedParamIndex)
            .map((p) => p.api_param_name);

    if (existingKeys?.includes(name)) {
      showToast('error', 'Please use different param name');
      return;
    }

    dispatch(
      addApiCardParam(props.orderIndex, selectedParamIndex, name, value)
    );

    setOpenParamModal(false);
    setSelectedParamIndex(-1);
  };

  return (
    <>
      <BlockCard
        id="api-card"
        type="API"
        Icon={<APIIcon />}
        onClickDelete={handleDeleteCard}
        tipText={cardAttributes[CardTypes.API].tip}
      >
        <APIActionForm action={api_action} orderIndex={props.orderIndex} />

        <Box mt={4}>
          <Typography fontWeight="600" variant="h6">
            Parameters
          </Typography>
          <Typography color="textSecondary">
            Define parameters to match the strings you have included in the API
            URL.
          </Typography>
          <Box my={2} borderTop={`1px solid ${theme.palette.grey[400]}`}>
            {api_params.map((param, index) => (
              <APICardButton
                key={param.api_param_name}
                onDelete={() => handleRemoveParam(index)}
              >
                <Button
                  variant="text"
                  onClick={() => handleOpenParamModal(index)}
                >
                  <Typography variant="caption" fontWeight="500">
                    {param.api_param_name}
                  </Typography>
                </Button>
              </APICardButton>
            ))}
          </Box>
          {api_params.length < 3 && (
            <Box display="flex" justifyContent="center" mb={2}>
              <Button
                variant="text"
                size="small"
                onClick={() => handleOpenParamModal()}
              >
                + Add Parameter
              </Button>
            </Box>
          )}
        </Box>
        <APICardTabs index={props.orderIndex} />
      </BlockCard>
      {openParamModal && (
        <APIParameterModal
          open
          parameter={api_params[selectedParamIndex]}
          handleAddParam={handleAddParam}
          handleClose={() => setOpenParamModal(false)}
        />
      )}
    </>
  );
};
