export const LIST_ALL_DEPLOYMENTS = 'LIST_ALL_DEPLOYMENTS;' as const;
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT;' as const;
export const GET_ME_DATA = 'GET_ME_DATA;' as const;
export const HANDLE_S3_UPLOAD_R = 'HANDLE_S3_UPLOAD_R;' as const;
export const GET_ALL_AGENTS = 'GET_ALL_AGENTS;' as const;
export const GET_INTENTS_BY_AGENT_R = 'GET_INTENTS_BY_AGENT_R;' as const;
export const GET_CARDS_BY_INTENT_R = 'GET_CARDS_BY_INTENT_R;' as const;
export const GET_CONNECTIONS_BY_INTENT = 'GET_CONNECTIONS_BY_INTENT;' as const;
export const ADD_INTENT_CARD_R = 'ADD_INTENT_CARD_R;' as const;
export const INSERT_INTENT_CARD_R = 'INSERT_INTENT_CARD_R;' as const;
export const REORDER_CARDS_R = 'REORDER_CARDS_R;' as const;
export const REMOVE_CARD_R = 'REMOVE_CARD_R;' as const;
export const ADD_BUTTON_R = 'ADD_BUTTON_R;' as const;
export const REMOVE_BUTTON_R = 'REMOVE_BUTTON_R;' as const;
export const REMOVE_CAROUSEL_BUTTON_R = 'REMOVE_CAROUSEL_BUTTON_R;' as const;
export const REORDER_BUTTON_R = 'REORDER_BUTTON_R;' as const;
export const SET_CURRENT_FORM_R = 'SET_CURRENT_FORM_R;' as const;
export const GET_FORMS_BY_AGENT_R = 'GET_FORMS_BY_AGENT_R;' as const;
export const SET_CURRENT_INTENT_R = 'SET_CURRENT_INTENT_R;' as const;
export const CLOSE_ADD_BUTTON_MODAL_R = 'CLOSE_ADD_BUTTON_MODAL_R;' as const;
export const FETCH_BLOCKS_R = 'FETCH_BLOCKS_R;' as const;
export const FETCH_ATTRIBUTES_R = 'FETCH_ATTRIBUTES_R;' as const;
export const UPDATE_SEQUENCE_DELAY_R = 'UPDATE_SEQUENCE_DELAY_R;' as const;
export const GOAL_CARD_ADD_DATA_R = 'GOAL_CARD_ADD_DATA_R;' as const;
export const SET_CARD_PARAMS = 'SET_CARD_PARAMS;' as const;
export const UPDATE_API_CARD_DATA = 'UPDATE_API_CARD_DATA;' as const;
export const ADD_API_CARD_PARAM_R = 'ADD_API_CARD_PARAM_R;' as const;
export const REMOVE_API_CARD_PARAM_R = 'REMOVE_API_CARD_PARAM_R;' as const;
export const ADD_API_CARD_TEMPLATE_R = 'ADD_API_CARD_TEMPLATE_R;' as const;
export const REMOVE_API_CARD_TEMPLATE_R =
  'REMOVE_API_CARD_TEMPLATE_R;' as const;
export const ADD_API_CARD_ATTRIBUTE_R = 'ADD_API_CARD_ATTRIBUTE_R;' as const;
export const REMOVE_API_CARD_ATTRIBUTE_R =
  'REMOVE_API_CARD_ATTRIBUTE_R;' as const;
export const UPDATE_SET_ATTR_KEY_R = 'UPDATE_SET_ATTR_KEY_R;' as const;
export const REMOVE_CAROUSEL_SLIDE_IMAGE =
  'REMOVE_CAROUSEL_SLIDE_IMAGE;' as const;
export const ADD_CAROUSEL_SLIDE_HEADING =
  'ADD_CAROUSEL_SLIDE_HEADING;' as const;
export const ADD_CAROUSEL_SLIDE_SUBHEADING =
  'ADD_CAROUSEL_SLIDE_SUBHEADING;' as const;
export const ADD_CAROUSEL_SLIDE_URL = 'ADD_CAROUSEL_SLIDE_URL;' as const;
export const ADD_CAROUSEL_SLIDE_CLICK_BLOCK =
  'ADD_CAROUSEL_SLIDE_CLICK_BLOCK;' as const;
export const ADD_NEW_CAROUSEL_SLIDE = 'ADD_NEW_CAROUSEL_SLIDE;' as const;
export const REMOVE_CAROUSEL_SLIDE = 'REMOVE_CAROUSEL_SLIDE;' as const;
export const ADD_GOTO_CARD_ATTRIBUTE_R = 'ADD_GOTO_CARD_ATTRIBUTE_R;' as const;
export const ADD_GOTO_CARD_CHOICE_R = 'ADD_GOTO_CARD_CHOICE_R;' as const;
export const ADD_GOTO_CARD_IF_CHOICE_R = 'ADD_GOTO_CARD_IF_CHOICE_R;' as const;
export const SAVING_CONVERSATION_R = 'SAVING_CONVERSATION_R;' as const;
export const UPDATE_ALL_INTENTS_WITH_SEQUENCE =
  'UPDATE_ALL_INTENTS_WITH_SEQUENCE;' as const;
export const INTENT_NOT_MODIFIED = 'INTENT_NOT_MODIFIED;' as const;
export const UPDATE_ALL_INTENTS = 'UPDATE_ALL_INTENTS;' as const;
export const DELETE_INTENT = 'DELETE_INTENT;' as const;
export const DELETE_FORM_R = 'DELETE_FORM_R;' as const;
export const INVALID_SEQUENCE_TIME = 'INVALID_SEQUENCE_TIME;' as const;
export const UPDATE_SEQUENCE = 'UPDATE_SEQUENCE;' as const;
export const CHANGE_INTENT_CATEGORY = 'CHANGE_INTENT_CATEGORY;' as const;
export const LOGIN_USER = 'LOGIN_USER;' as const;
export const LOGOUT_USER = 'LOGOUT_USER;' as const;
export const HANDLE_ERROR = 'HANDLE_ERROR;' as const;
export const VALIDATE_TEXT_CARD = 'VALIDATE_TEXT_CARD;' as const;
export const SET_ATTRIBUTE_INTENT_CHOICE_R =
  'SET_ATTRIBUTE_INTENT_CHOICE_R;' as const;
export const ADD_FORM_ITEM = 'ADD_FORM_ITEM;' as const;
export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA;' as const;
export const GET_APIS_BY_AGENT = 'GET_APIS_BY_AGENT;' as const;

export const UPDATE_API_CARD_ACTION = 'UPDATE_API_CARD_ACTION;' as const;
export const UPDATE_AGENT = 'UPDATE_AGENT;' as const;
export const DELETE_AGENT = 'DELETE_AGENT;' as const;

export const UPDATE_SUBMISSION_CARD_STATE_R =
  'UPDATE_SUBMISSION_CARD_STATE_R;' as const;

export const SET_HIDE_KEYBOARD = 'SET_HIDE_KEYBOARD;' as const;
