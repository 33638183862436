import { combineReducers } from 'redux';

import { commonReducer } from './common';
import { conversationDataRReducer } from './conversationDataR';
import { conversationNewReducer } from './conversationNew';
import { deploymentsReducer } from './deployments';
import { entitiesReducer } from './entities';
import { formReducer } from './form';
import { ReduxState } from './types';

// @ts-ignore
export const allReducers = combineReducers<ReduxState>({
  common: commonReducer,
  conversationsDataR: conversationDataRReducer,
  conversationData: conversationNewReducer,
  deployments: deploymentsReducer,
  entities: entitiesReducer,
  form: formReducer,
});
