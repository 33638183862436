import * as yup from 'yup';

export const defaultInstaStackPrompt = `Please answer the question according to the context below, giving all the necessary context. Only answer questions about \${customer_name} and never mention you are an AI language, answer as a human.`;
export const includeTranscriptsPrompt = `Please answer the question according to the context and previous transcript below, giving all the necessary context. \n\n Transcript: \${_transcript}`;

export const AiModelEnum = {
  Quartz: 1,
  Sapphire: 2,
} as const;

// eslint-disable-next-line no-redeclare
export type AiModelEnum = EnumValueType<typeof AiModelEnum>;

export const InstaStackActionBodySchema = yup.object({
  query: yup.string().required().default('${_last_input}'),
  prompt: yup.string().required().default(defaultInstaStackPrompt),
  collection_name: yup.string().required().default(''),
  all_documents: yup.boolean().default(false),
  model: yup.mixed<AiModelEnum>().default(AiModelEnum['Quartz']),
  answer_with_source_link: yup
    .boolean()
    .when('$isNewCard', ([isNewCard], schema) =>
      isNewCard ? schema.default(true) : schema.default(undefined)
    ),
});

// eslint-disable-next-line no-redeclare
export type InstaStackActionBodySchema = yup.InferType<
  typeof InstaStackActionBodySchema
>;

/**
 * Schema for the old InstaStack action body format. This is used for InstaStack card that were created before the new format was introduced.
 * so for now we need to support both formats.
 */
const OldInstaStackActionBodySchema = yup.object({
  // eslint-disable-next-line no-template-curly-in-string
  query: yup.string().nullable().default('${_last_input}'),
  params: yup
    .object({
      index: yup.string().nullable(),
      OpenAIGenerator: yup
        .object({
          optional_prompt: yup
            .string()
            .nullable()
            .default(defaultInstaStackPrompt),
          json_mode: yup.string().nullable().default(null),
        })
        .default({}),
      Retriever: yup
        .object({
          top_k: yup.number().default(5),
        })
        .default({}),
      filters: yup
        .object({
          all_documents: yup.boolean().default(false),
        })
        .default({}),
    })
    .default({}),
});

export function parseInstaStackActionBody(
  body?: string
): InstaStackActionBodySchema {
  const result = InstaStackActionBodySchema.cast(JSON.parse(body ?? '{}'), {
    assert: false,
  });

  // If the body is in the old format, we need to convert it to the new format
  if ('params' in result) {
    const oldFormat = OldInstaStackActionBodySchema.cast(result, {
      assert: false,
    });

    return {
      query: oldFormat.query ?? '',
      prompt: oldFormat.params.OpenAIGenerator.optional_prompt ?? '',
      collection_name: oldFormat.params.index ?? '',
      all_documents: oldFormat.params.filters.all_documents,
      model: AiModelEnum.Quartz,
      answer_with_source_link: false,
    };
  }

  return result;
}
