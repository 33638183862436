import { User } from './types';
import { axiosClient } from '../axiosClient';

export type UpdateMeType = Pick<
  User,
  'account_id' | 'user_id' | 'first_name' | 'last_name' | 'email'
>;

export const updateMe = (user: UpdateMeType) => {
  return axiosClient.put('/me', user);
};
