import {
  Sidebar,
  SidebarRoot,
  Inbox,
  useSidebarCommentsButton,
  InboxEmptyState,
  useInbox,
} from '@collabkit/react';
import { useDraggable } from '@dnd-kit/core';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import * as React from 'react';

import { SearchInput } from '@botco/library';
import CrossIcon from '~/assets/icons/cross.svg?react';
import DotsNineIcon from '~/assets/icons/dots-nine.svg?react';

type Props = {
  xDelta?: number;
};

export const CollaboratorSidebar = ({ xDelta }: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable',
  });
  const [filter, setFilter] = React.useState<string>('');

  const commentFilter = (comment: string) =>
    new RegExp(filter, 'i').test(comment);

  const inbox = useInbox({ commentFilter });

  const { onClick: onClose } = useSidebarCommentsButton();
  const classes = useStyles();

  const style = transform
    ? {
        transform: `translate3d(${transform.x + (xDelta ?? 0)}px, ${
          transform.y
        }px, 0)`,
        cursor: 'grabbing',
      }
    : {
        transform: `translate3d(${xDelta}px, 0, 0)`,
      };

  return (
    <div className={classes.root} ref={setNodeRef}>
      <Sidebar>
        <SidebarRoot className={classes.paper} style={style}>
          <AppBar
            position="relative"
            color="inherit"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.header}>
              <div {...listeners} {...attributes} data-testid="target-dot">
                <DotsNineIcon className={classes.dotsIcon} />
              </div>
              <SearchInput
                name=""
                value={filter}
                onChange={(_, value) => setFilter(value)}
                fullWidth
              />
              <CrossIcon className={classes.closeIcon} onClick={onClose}>
                close
              </CrossIcon>
            </Toolbar>
          </AppBar>
          {inbox.length > 0 ? (
            <Inbox commentFilter={commentFilter} />
          ) : (
            <InboxEmptyState />
          )}
        </SidebarRoot>
      </Sidebar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'unset',
    position: 'absolute',
    zIndex: 0,
  },
  appBar: {
    position: 'sticky',
    top: 0,
  },
  paper: {
    transition: 'height 4s easy-in',
    top: 60,
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(56),
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    borderLeft: 'none',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.15);',

    position: 'fixed',
    left: 'auto',
    right: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  closeIcon: {
    cursor: 'pointer',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: theme.palette.grey[900],
  },
  dotsIcon: {
    cursor: 'grab',
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.grey[900],
  },
}));
