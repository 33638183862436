import { RedirectProps } from 'react-router-dom';

import { ContactsFiltersSchema } from '~/containers/Contacts/Contacts/components/ContactFilterDialog/ContactFilterDialog.types';
import { DeployQueryParamsType } from '~/containers/Launch/Deploy/contexts/DeployProvider/DeployProvider.types';

export type BotcoRoute<T extends Record<string, string | number>> = {
  path: string;
  get: (agentId: number, params: T) => RedirectProps['to'];
  title: string;
  hideSelectChatbotButton?: boolean;
};

const mapObjectToQueryParams = (obj: Record<string, unknown>) => {
  const entries = Object.entries(obj)
    .filter(([_key, value]) => Boolean(value))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.join(',')];
      }

      if (typeof value === 'object') {
        return [key, JSON.stringify(value)];
      }

      return [key, value?.toString()];
    });

  return new URLSearchParams(Object.fromEntries(entries));
};

export const routes = {
  authoringWizard: {
    path: '/authoring-wizard/:agentId',
    get: (agentId: number) => `/authoring-wizard/${agentId}`,
    title: 'Bot Studio',
  },
  authoringWizardBlock: {
    path: '/authoring-wizard/:agentId/blocks/:blockId',
    get: (agentId: number, { blockId }: { blockId: number }) =>
      `/authoring-wizard/${agentId}/blocks/${blockId}`,
    title: 'Bot Studio',
  },
  authoringWizardForm: {
    path: '/authoring-wizard/:agentId/forms/:formId',
    get: (agentId: number, { formId }: { formId: number }) =>
      `/authoring-wizard/${agentId}/forms/${formId}`,
    title: 'Bot Studio',
  },
  authoringWizardPersistentNav: {
    path: '/authoring-wizard/:agentId/persistent-nav',
    get: (agentId: number) => `/authoring-wizard/${agentId}/persistent-nav`,
    title: 'Bot Studio',
  },
  broadcast: {
    path: '/broadcast/:agentId',
    get: (agentId: number) => `/broadcast/${agentId}`,
    title: 'Broadcast',
  },
  chatbotForm: {
    path: '/conversations/:conversation_id/forms-v2/:formId',
    get: (agentId: number, { formId }: { formId: number }) =>
      `/conversations/${agentId}/forms-v2/${formId}`,
    title: 'Bot Studio',
  },
  chatbotNewForm: {
    path: '/conversations/:conversation_id/forms-v2/new/:formName',
    get: (agentId: number, formName: string) =>
      `/conversations/${agentId}forms-v2/new/${formName}`,
    title: 'Bot Studio',
  },
  chatbotFormFormP1: {
    path: '/conversations/:conversation_id/forms/:formId',
    get: (agentId: number, { formId }: { formId: number }) =>
      `/conversations/${agentId}/forms/${formId}`,
    title: 'Bot Studio',
  },
  chatbotOverview: {
    path: '/conversations/:conversation_id/overview',
    get: (agentId: number, { blockId }: { blockId?: number } = {}) => {
      const url = `/conversations/${agentId}/overview`;
      if (blockId) {
        const params = new URLSearchParams({ blockId: blockId.toString() });
        return `${url}?${params.toString()}`;
      }

      return url;
    },
    title: 'Bot Studio',
  },
  chatbotSequence: {
    path: '/conversations/:conversation_id/sequences/:sequenceId',
    get: (agentId: number, { sequenceId }: { sequenceId: number }) =>
      `/conversations/${agentId}/sequences/${sequenceId}`,
    title: 'Bot Studio',
  },
  chatbotDetails: {
    path: '/conversations/:conversation_id',
    get: (
      agentId: number,
      { blockId, tab }: { blockId?: number; tab?: 'preview' | 'chat' } = {}
    ) => {
      const url = `/conversations/${agentId}`;
      if (blockId || tab) {
        const params = new URLSearchParams({
          ...(blockId && { blockId: blockId.toString() }),
          ...(tab && { tab }),
        });
        return `${url}?${params.toString()}`;
      }

      return url;
    },
    title: 'Bot Studio',
  },
  chatbots: {
    path: '/conversations',
    get: () => '/conversations',
    title: 'Bot Studio',
    hideSelectChatbotButton: true,
  },
  contactsLegacy: {
    path: '/contacts-v1',
    get: (agentId?: number) => {
      if (agentId) {
        return `/contacts-v1?agentId=${agentId}`;
      }

      return '/contacts-v1';
    },
    title: 'Contacts',
  },
  contacts: {
    path: '/contacts/:contactId?',
    get: (
      agentId: number,
      {
        contactId,
        options,
      }: {
        contactId?: string;
        options?: {
          keepCurrentParams?: boolean;
          filters?: Partial<ContactsFiltersSchema>;
        };
      } = {}
    ) => {
      const params = new URLSearchParams(
        options?.keepCurrentParams
          ? window.location.search
          : options?.filters
            ? mapObjectToQueryParams(options.filters)
            : { dataSubmit: '*' }
      );

      if (agentId) {
        params.set('agentId', agentId.toString());
      }

      return `/contacts${contactId ? `/${contactId}` : ''}?${params.toString()}`;
    },
    title: 'Users',
  },
  contactLegacyDetails: {
    path: '/contacts-v1/:id',
    get: (agentId: number, { contactId }: { contactId: number }) => {
      if (agentId) {
        return `/contacts-v1/${contactId}?agentId=${agentId}`;
      }

      return `/contacts-v1/${contactId}`;
    },
    title: 'Contacts',
  },
  dashboard: {
    path: '/dashboard/:conversation_id',
    get: (agentId: number) => {
      return `/dashboard/${agentId}`;
    },
    title: 'Dashboard',
  },
  dashboardBlocks: {
    path: '/dashboard/:conversation_id/blocks',
    get: (agentId: number) => `/dashboard/${agentId}/blocks`,
    title: 'Dashboard',
  },
  deployments: {
    path: '/deployments',
    get: () => '/deployments',
    title: 'Deployments',
    hideSelectChatbotButton: true,
  },
  newDeploymentBeta: {
    path: '/deployments/deploy',
    get: (agentId?: number, params?: DeployQueryParamsType) => {
      if (!agentId && !params) {
        return '/deployments/deploy';
      }

      const entries = Object.entries({ ...params, agentId }).map(
        ([key, value]) => [key, value?.toString()]
      );

      const qs = new URLSearchParams(Object.fromEntries(entries));

      return `/deployments/deploy?${qs.toString()}`;
    },
    title: 'Deploy',
    hideSelectChatbotButton: true,
  },
  blocks: {
    path: '/blocks/:conversation_id',
    get: (agentId: number) => `/blocks/${agentId}`,
    title: 'Blocks',
  },
  aiTraining: {
    path: '/ai-training/:agentId',
    get: (agentId: number) => `/ai-training/${agentId}`,
    title: 'Training',
  },
  training: {
    path: '/training/:conversation_id',
    get: (agentId: number) => `/training/${agentId}`,
    title: 'Training',
  },
  trainingDetails: {
    path: '/training/:conversation_id/:id/:clusterNumber',
    get: (
      agentId: number,
      { clusterId, clusterNumber }: { clusterId: number; clusterNumber: number }
    ) => `/training/${agentId}/${clusterId}/${clusterNumber}`,
    title: 'Training',
  },
  instaStackAILogs: {
    path: '/insta-stack/:agentId/ai-logs',
    get: (agentId?: number | null) => `/insta-stack/${agentId || ''}/ai-logs`,
    title: 'AI Logs',
  },
  instaStackAIPlayground: {
    path: '/insta-stack/:agentId/ai-playground',
    get: (agentId?: number | null) =>
      `/insta-stack/${agentId || ''}/ai-playground`,
    title: 'AI Playground',
  },
  instaStackData: {
    path: '/insta-stack/data/:agentId?',
    get: (agentId?: number | null) => `/insta-stack/data/${agentId || ''}`,
    title: 'Knowledge Base',
  },
  instaStackUrlData: {
    path: '/insta-stack/data/:agentId/:collectionId/:url',
    get: (
      agentId: number,
      { url, collectionId }: { url: string; collectionId: number }
    ) => `/insta-stack/data/${agentId}/${collectionId}/${window.btoa(url)}`,
    title: 'Knowledge Base',
  },
  helpCenter: {
    path: '/help-center',
    get: () => '/help-center',
    title: 'Help Center',
    hideSelectChatbotButton: true,
  },
  knowledgeBase: {
    path: '/knowledge-base',
    get: () => '/knowledge-base',
    title: 'Knowledge Base',
  },
  settings: {
    path: '/manage-account',
    get: () => '/manage-account',
    title: 'Manage Account',
    hideSelectChatbotButton: true,
  },
} satisfies Record<string, BotcoRoute<any>>;
